import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MMService } from 'src/app/services/mm.service';

@Component({
  selector: 'app-authors',
  templateUrl: './authors.component.html',
  styleUrls: ['./authors.component.scss'],
})
export class AuthorsComponent implements OnInit {
  remedy:any;
  mmList:Array<any>;
  constructor(private router:Router,private activatedRoute:ActivatedRoute,private mmService:MMService) { 
    this.remedy = this.router.getCurrentNavigation().extras.state.remedy;
    this.activatedRoute.params.subscribe((params)=>{
      this.mmService.getMMlist().subscribe((mmList)=>this.mmList = mmList);
    })
  }

  ngOnInit() {}

  getMM(mmId:string){
    return this.mmList.find((mm)=>mm._id==mmId);
  }

}
