import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { Observable } from 'rxjs';
import {map} from "rxjs/internal/operators/map";
@Injectable({
  providedIn: 'root'
})
export class MMService {

  constructor(private firestore : AngularFirestore) { }
  getThumbnailUrl = (image)=>{
    return `https://raw.githubusercontent.com/smarthomeopath/homeoremedies/master/thumbnails/${image}`;
  };
  getRemedyList():Observable<Array<any>>{
    const collection = this.firestore.collection('remedies',ref => ref.orderBy('name'));
    const remedies:Observable<Array<any>> = collection.valueChanges();
    console.log(remedies)
    return remedies.pipe(map((remedies)=>{
      return remedies.map((remedy)=>{
        let images = remedy.images ? remedy.images.split(","):[];
        let thumbnail = images.length && images[0]?this.getThumbnailUrl(images[0]):this.getThumbnailUrl("placeholder.png");
        remedy.thumbnail = thumbnail;
        return remedy;
      });
    }))

  }

  getMMlist():Observable<Array<any>>{
    let mmlist =  this.firestore.collection('materia-medica').valueChanges();
    return mmlist;
  }

  getRemedyDetails(id){
    return this.firestore.collection('remedies',ref => ref.where('_id',"==",id)).valueChanges().pipe(map(col=>col[0]))
  }
  
}
