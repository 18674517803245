import { Component, OnInit } from '@angular/core';
import { MMService } from 'src/app/services/mm.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-remedies-list',
  templateUrl: './remedies-list.component.html',
  styleUrls: ['./remedies-list.component.scss'],
})
export class RemediesListComponent implements OnInit {
  remediesList:Observable<Array<any>>;
  remedies:Array<any>;
  constructor(private mmSvc:MMService,private router:Router) { }

  ngOnInit() {
    this.remediesList = this.mmSvc.getRemedyList();
    this.remediesList.subscribe(remedies=>this.remedies = remedies);
  }
  navigateToRemedyProfile(remedyId){
    this.router.navigate(['remedy-details',`${remedyId}`]);

  }

}
