import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MMService } from 'src/app/services/mm.service';

@Component({
  selector: 'app-remedy-profile',
  templateUrl: './remedy-profile.component.html',
  styleUrls: ['./remedy-profile.component.scss'],
})
export class RemedyProfileComponent implements OnInit {
  remedyId:string;
  selectedTab:string;
  remedy:any;
  constructor(private router:Router,private activatedRoute:ActivatedRoute,private mmService:MMService) {
    this.activatedRoute.params.subscribe((params)=>{
      this.remedyId = params['remedyId'];
      this.mmService.getRemedyDetails(this.remedyId).subscribe((remedy)=>{
        this.remedy = remedy;
        this.selectedTab = 'authors';
        this.navigateTo('authors');
      })

    })
   }

  ngOnInit() {
    
  }

  navigateTo(route:string){
    this.router.navigate([`remedy-details/${this.remedyId}`,route],{state:{remedy:this.remedy}});
    this.selectedTab = route;
  }

}
