import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { RemediesListComponent } from './remedies-list/remedies-list.component';
import { RemedyProfileComponent } from './remedy-profile/remedy-profile.component';
import { AuthorsComponent } from './remedy-profile/authors/authors.component';
import { ProfileComponent } from './remedy-profile/profile/profile.component';
import { DiseasesComponent } from './remedy-profile/diseases/diseases.component';

const routes:Routes = [
{
   path:"",
   component:RemediesListComponent,
   pathMatch:"full",

},{
   path:"remedy-details/:remedyId",
   component:RemedyProfileComponent,
   children:[
     {
       path:"authors",
       component:AuthorsComponent
     },
     {
       path:"profile",
       component:ProfileComponent
     },
     {
       path:"diseases",
       component:DiseasesComponent
     }
   ]
 }
]

@NgModule({
  declarations: [RemediesListComponent,RemedyProfileComponent,AuthorsComponent,ProfileComponent,DiseasesComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ]
})
export class RemediesModule { }
